import React from 'react'
import s from "./Set.module.css";
import NavButtonZapis from "../Buttons/navButtonZapis/NavButtonZapis";


const Set = ({N,p1,p2,p3,p4,p5,p6,setImage,openModal}) => {
    return <div>
        <div className={s.container}>
            <div className={s.wrapText}>
                <div className={s.circle}>
                    <img src={setImage} />
                </div>
                <h1 className={s.h1}>Набор N {N}</h1>
                <h3>{p1}</h3>
                <h3>{p2}</h3>
                <h3>{p3}</h3>
                <h3>{p4}</h3>
                <h3>{p5}</h3>
                <h3>{p6}</h3>
            </div>
            <div  className={s.button}>
                <NavButtonZapis openModal={openModal} buttonText={"Получить скидку 30%"}></NavButtonZapis>
            </div>

        </div>

    </div>
}

export default Set;

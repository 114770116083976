import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import s from './Slider.module.css'

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Slide from "../Slide/Slide";
import Slide2 from "../Slide/Slide2";
import Slide4 from "../Slide/Slide4";
import Slide5 from "../Slide/Slide5";
import Slide3 from "../Slide/Slide3";


const Slider = () => {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className={s.swiper}
            >
                <SwiperSlide className={s.swiperSlide}><Slide/></SwiperSlide>
                <SwiperSlide className={s.swiperSlide}><Slide2/></SwiperSlide>
                <SwiperSlide className={s.swiperSlide}><Slide3/></SwiperSlide>
                <SwiperSlide className={s.swiperSlide}><Slide4/></SwiperSlide>
                <SwiperSlide className={s.swiperSlide}><Slide5/></SwiperSlide>
            </Swiper>
        </>
    );
};

export default Slider;
import React from 'react'
import s from './Slide.module.css'
import Slide1 from '../../image/slide/2.JPG'


const Slide2 = () => {
    return <div className={s.Slide}>
        <div className={s.SlideDescription}>
            <h2>
                Не нужно отращивать волосы как для депиляции
            </h2>
            <p>
                {/*Забудешь о постоянном бритье*/}

                {/*3.Слово «боль» перестанет ассоциироваться у тебя с удалением волос*/}
                {/*4.Избавишься от вросших волос на коже от депиляции*/}
                {/*5.Приобретешь гладкую кожу без раздражения*/}
            </p>
        </div>
        <div className={s.WrapperImage}>
            <img src={Slide1} className={s.Image}/>
        </div>
    </div>
};

export default Slide2;
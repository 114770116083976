import React from 'react'
import s from "./Protivopokazaniya.module.css"


const Protivopokazaniya = () => {
    return <div>
        <div className={s.wrap}>
            <div className={`${s.spisok} ${s.column3}`}>
                <li>️беременность, лактация ️</li>
                <li>️загар в течение недели</li>
                <li>️повышенная температура тела</li>
                <li>наличие кардиостимулятора</li>
                <li>туберкулёз</li>
                <li>эпилепсия</li>
                <li>миома матки</li>
                <li>онкология</li>
                <li>псориаз</li>
                <li>️витилиго</li>
                <li>лишай</li>
                <li>герпес в активной фазе</li>
            </div>
        </div>

    </div>
}

export default Protivopokazaniya;



import React from 'react';
import s from './Footer.module.css'
import CustomLink from "../CustomLink/CustomLink";

const Footer = () => {
    return <footer >
        <div className={s.footer}>
            <p>Наша миссия - делать мир лучше и прекраснее, осознавая важность красоты и здоровья женщин.
                Мы стремимся приносить нежность и любовь к себе в жизнь каждой женщины через наши услуги лазерной эпиляции.
                Мы верим в то, что нежное отношение к себе способно преобразить не только внешность, но и внутреннюю гармонию.</p>
        </div>
    </footer>
}

export default Footer;
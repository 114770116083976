import React from 'react'
import s from './Slide.module.css'
import Slide1 from '../../image/slide/photo_2020-02-02_14-55-04.jpg'


const Slide5 = () => {
    return <div className={s.Slide}>
        <div className={s.SlideDescription}>
            <h2>
                Гладкая кожа без раздражения
            </h2>
            <p>
                {/*Забудешь о постоянном бритье*/}
                {/*Не будешь больше отращивать волосы для депиляции*/}
                {/*3.Слово «боль» перестанет ассоциироваться у тебя с удалением волос*/}
                {/*4.Избавишься от вросших волос на коже от депиляции*/}

            </p>
        </div>
        <div className={s.WrapperImage}>
            <img src={Slide1} className={s.Image}/>
        </div>
    </div>
};

export default Slide5;
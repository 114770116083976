import React, {useState} from "react";
import s from './Content.module.css'
import Slider from "../Slider/Slider";
import Zagolovok from "../Zagolovok/Zagolovok";
import SliderDoPosle from "../SliderDoPosle/SliderDoPosle";
import Set from "../Set/Set";
import setImage1 from "../../image/Set1.png";
import setImage2 from "../../image/Set7.png";
import setImage3 from "../../image/Set3.png";
import setImage4 from "../../image/Set8.png";
import setImage5 from "../../image/Set6.png";
import Modal from "../Modal/Modal";
import Protivopokazaniya from "../Protivopokazaniya/Protivopokazaniya";

const Content = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const openModalWithDelay = () => {
        // Устанавливаем состояние модального окна в true через 500 миллисекунд (половина секунды)
        setTimeout(() => {
            setIsModalOpen(true);
        }, 300);
    };
    return <div className={s.content}>
        <div>
            <Zagolovok textH1={"Добро пожаловать"} textH2={"в LaserQueen лазерная эпиляция Красноярск"}/>
        </div>
        <div>
            <Slider/>
        </div>
        <div>
            <Zagolovok  textH2={"Противопоказания"}/>
        </div>
        <div>
            <Protivopokazaniya></Protivopokazaniya>
        </div>
        <div>
            <Zagolovok textH2={"До / После"} showLine={false}/>
        </div>
        <div>
            <SliderDoPosle></SliderDoPosle>
        </div>
        <div>
            <Modal isOpen={isModalOpen} onClose={closeModal}/>
        </div>
        <div>
            <Zagolovok textH2={"Наборами выгоднее"} showLine={false}/>
        </div>
        <div className={s.wrapSet}>
            <div>
                <Set N={"1"} p1={"Бикини любое"} p2={"Подмышки"} setImage={setImage1} openModal={openModalWithDelay}></Set>
            </div>
            <div>
                <Set N={"2"} p1={"Бикини любое"} p2={"Подмышки"} p3={"Голени"} setImage={setImage2} openModal={openModalWithDelay}></Set>
            </div>
            <div>
                <Set N={"3"} p1={"Бикини любое"} p2={"Подмышки"} p3={"Голени"} p4={"1/2 Бедра"} setImage={setImage3} openModal={openModalWithDelay}></Set>
            </div>
            <div>
                <Set N={"4"} p1={"Бикини любое"} p2={"Подмышки"} p3={"Ноги"} p4={"Живот"} p5={"Лицо"} setImage={setImage4} openModal={openModalWithDelay}></Set>
            </div>
            <div>
                <Set N={"5"} p1={"Бикини любое"} p2={"Подмышки"} p3={"Ноги"} p4={"Руки"} p5={"Лицо"} p6={"Живот"} setImage={setImage5} openModal={openModalWithDelay}></Set>
            </div>

        </div>
    </div>
}

export default Content;
import React, {useState} from 'react'
import s from './Price.module.css'
import PriceImage from "../../image/priceWoman.png";
import TablePrice from "../TablePrice/TablePrice";
import Set from "../Set/Set";
import setImage1 from "../../image/Set1.png";
import setImage2 from "../../image/Set7.png";
import setImage3 from "../../image/Set3.png";
import setImage4 from "../../image/Set8.png";
import setImage5 from "../../image/Set6.png";
import Modal from "../Modal/Modal";

const Price = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const openModalWithDelay = () => {
        // Устанавливаем состояние модального окна в true через 500 миллисекунд (половина секунды)
        setTimeout(() => {
            setIsModalOpen(true);
        }, 300);
    };

    const dataWomen = [
        { id:1, time: '15 мин', name: 'Бикини классическое', price: '690₽' },
        { id:2, time:'15 мин', name: 'Бикини среднее', price: '890₽' },
        { id:3, time:'20 мин', name: 'Бикини глубокое', price: '1190₽'},
        { id:4, time:'10 мин', name: 'Дорожка на животе', price: '190₽'},
        { id:5, time:'20 мин', name: 'Живот полностью', price: '590₽'},
        { id:6, time:'15 мин', name: 'Подмышки', price: '590₽'},
        { id:7, time:'20 мин', name: 'Грудь/ареолы', price: '490₽'},
        { id:8, time:'30 мин', name: 'Спина', price: '1390₽'},
        { id:9, time:'20 мин', name: 'Поясница', price: '590₽'},
        { id:10, time: '20 мин', name: 'Ягодицы', price: '590₽'},
        { id:11, time: '30 мин', name: 'Голени', price: '2390₽'},
        { id:12, time: '30 мин', name: 'Бедра', price: '2890₽'},
        { id:13, time: '60 мин', name: 'Ноги полностью', price: '4490₽'},
        { id:14, time: '20 мин', name: 'Руки', price: '1390₽'},
        { id:15, time: '15 мин', name: 'Верхняя губа', price: '490₽'},
        { id:16, time: '15 мин', name: 'Подбородок', price: '490₽'},
        { id:17, time: '15 мин', name: 'Щеки/бакенбарды', price: '890₽'},
        { id:18, time: '15 мин', name: 'Шея одна сторона', price: '490₽'},
        { id:19, time: '15 мин', name: 'Межбровье', price: '190₽'},
        { id:20, time: '30 мин', name: 'Лицо полностью', price: '1690₽'},
    ];

    const dataSeti = [
        { id:1, time: '25 мин', name: 'Бикини классическое', price: '1190₽' },
        { id:3, time:'30 мин', name: 'Бикини глубокое', price: '1890₽'},
        { id:5, time:'30 мин', name: 'Живот полностью', price: '1390₽'},
        { id:6, time:'25 мин', name: 'Подмышки', price: '890₽'},
        { id:7, time:'30 мин', name: 'Грудь', price: '1990₽'},
        { id:8, time:'40 мин', name: 'Спина', price: '2590₽'},
        { id:10, time: '30 мин', name: 'Ягодицы', price: '890₽'},
        { id:11, time: '40 мин', name: 'Голени', price: '2590₽'},
        { id:12, time: '40 мин', name: 'Бедра', price: '3090₽'},
        { id:13, time: '70 мин', name: 'Ноги полностью', price: '5090₽'},
        { id:14, time: '30 мин', name: 'Руки', price: '2390₽'},
        { id:15, time: '25 мин', name: 'Верхняя губа', price: '790₽'},
        { id:16, time: '25 мин', name: 'Подбородок', price: '990₽'},
        { id:17, time: '25 мин', name: 'Щеки/бакенбарды', price: '1690₽'},
        { id:18, time: '25 мин', name: 'Шея одна сторона', price: '890₽'},
        { id:19, time: '25 мин', name: 'Межбровье', price: '590₽'},
        { id:20, time: '40 мин', name: 'Лицо полностью', price: '3390₽'},

    ];

    return <div className={s.price}>
        <div>
            <Modal isOpen={isModalOpen} onClose={closeModal}/>
        </div>
        <TablePrice data={dataWomen} caption={"ЖЕНСКИЙ ПРАЙС"}></TablePrice>
        <div className={s.wrapSet}>
            <div>
                <Set N={"1"} p1={"Бикини любое"} p2={"Подмышки"} setImage={setImage1} openModal={openModalWithDelay}></Set>
            </div>
            <div>
                <Set N={"2"} p1={"Бикини любое"} p2={"Подмышки"} p3={"Голени"} setImage={setImage2} openModal={openModalWithDelay}></Set>
            </div>
            <div>
                <Set N={"3"} p1={"Бикини любое"} p2={"Подмышки"} p3={"Голени"} p4={"1/2 Бедра"} setImage={setImage3} openModal={openModalWithDelay}></Set>
            </div>
            <div>
                <Set N={"4"} p1={"Бикини любое"} p2={"Подмышки"} p3={"Ноги"} p4={"Живот"} p5={"Лицо"} setImage={setImage4} openModal={openModalWithDelay}></Set>
            </div>
            <div>
                <Set N={"5"} p1={"Бикини любое"} p2={"Подмышки"} p3={"Ноги"} p4={"Руки"} p5={"Лицо"} p6={"Живот"} setImage={setImage5} openModal={openModalWithDelay}></Set>
            </div>
        </div>
        <TablePrice data={dataSeti} caption={"МУЖСКОЙ ПРАЙС"}></TablePrice>
    </div>
}

export default Price;
import React from 'react'
import s from './Slide.module.css'
import Slide1 from '../../image/slide/4.jpg'


const Slide4 = () => {
    return <div className={s.Slide}>
        <div className={s.SlideDescription}>
            <h2>
                Избавишься от вросших волос на коже от депиляции
            </h2>
            <p>
                {/*Забудешь о постоянном бритье*/}
                {/*Не будешь больше отращивать волосы для депиляции*/}
                {/*3.Слово «боль» перестанет ассоциироваться у тебя с удалением волос*/}

                {/*5.Приобретешь гладкую кожу без раздражения*/}
            </p>
        </div>
        <div className={s.WrapperImage}>
            <img src={Slide1} className={s.Image}/>
        </div>
    </div>
};

export default Slide4;
import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import s from './SliderDoPosle.module.css'
import { Autoplay, Pagination, Navigation,Grid,Controller } from 'swiper/modules';
import Doposle from "../DoPosle/Doposle";

import image1_1 from "../../image/doposle11.jpg"
import image1_2 from "../../image/doposle12.jpg"
import image2_1 from "../../image/doposle21.jpg"
import image2_2 from "../../image/doposle22.jpg"
import image4_1 from "../../image/doposle41.jpg"
import image4_2 from "../../image/doposle42.jpg"
import image6_1 from "../../image/doposle61.jpg"
import image6_2 from "../../image/doposle62.jpg"

import image8_1 from "../../image/posle8.jpg"

import image8_2 from "../../image/posle88.jpg"

const SliderDoPosle = () => {
    return (
        <div className={s.wrap}>
            <Swiper
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                }}
                breakpoints={{

                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                    reverseDirection: true
                }}
                modules={[Autoplay, Pagination, Navigation,Grid,Controller]}
                pagination={true
                }
                style={{
                    "--swiper-pagination-color": "#ec70e8",
                    "--swiper-navigation-color": "#ec70e8",
                }}
                navigation={true}
                loop={true}
                className={s.swiper}
            >
                <SwiperSlide className={s.swiperSlide}><Doposle image1={image6_1} image2={image6_2} countProcedur={8}></Doposle></SwiperSlide>
                <SwiperSlide className={s.swiperSlide}><Doposle image1={image4_1} image2={image4_2} countProcedur={4}></Doposle></SwiperSlide>
                <SwiperSlide className={s.swiperSlide}><Doposle image1={image2_1} image2={image2_2} countProcedur={2}></Doposle></SwiperSlide>
                {/*<SwiperSlide className={s.swiperSlide}><Doposle image1={image8_2} countProcedur={8} showOne={true}></Doposle></SwiperSlide>*/}
                {/*<SwiperSlide className={s.swiperSlide}><Doposle image1={image8_1} countProcedur={8} showOne={true}></Doposle></SwiperSlide>*/}
                <SwiperSlide className={s.swiperSlide}><Doposle image1={image1_1} image2={image1_2} countProcedur={1}></Doposle></SwiperSlide>
            </Swiper>
        </div>
    );
};

export default SliderDoPosle;
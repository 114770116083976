import React, {useState} from "react";
import s from './NavButtonZapis.module.css'


const NavButtonZapis = ({ openModal ,buttonText}) => {

    return <div>
        <button onClick={openModal} className={s.button}>
            <div>{buttonText}</div>
        </button>
    </div>

}

export default NavButtonZapis;

import React from 'react';
import s from './Zagolovok.module.css'

const Zagolovok = ({textH1,textH2,showLine=true}) => {

    return (
        <div className={s.wrap}>
            <h1 className={s.h1}>{textH1}</h1>
            <h2 className={s.h2}>{textH2}</h2>
            {showLine &&            <div className={s.lines}>
                <div className={s.line}></div>
                <div className={s.line}></div>
            </div>}
        </div>
        )
}
export default Zagolovok;
import React from 'react';
import {YMaps, Map, Placemark, FullscreenControl, ZoomControl} from '@pbe/react-yandex-maps';
import s from './Contacts.module.css'
import Zagolovok from "../Zagolovok/Zagolovok";
import CustomLink from "../CustomLink/CustomLink";
import { SocialIcon } from 'react-social-icons';
import screen2gis from '../../image/Map2gis.png'

const Contacts = () => {
    const defaultState = {
        center: [56.053774, 92.894505],
        zoom: 17,
        controls: [],
    };

    return (
        <div className={s.wrap}>
            <div className={s.contacts}>
                <div>
                    <Zagolovok textH1={"Наши контакты"}/>
                </div>
                <div>
                    <CustomLink to="tel:+79082086314">+7 908 208-63-14</CustomLink>
                </div>

                <div><SocialIcon bgColor="#5c5a5b" style={{ height: 35, width: 35 }}  url="https://www.instagram.com/laser4queen/" /></div>
                <div><SocialIcon bgColor="#5c5a5b" style={{ height: 35, width: 35 }} url="https://vk.com/laser4queen" /></div>
                <div><p>Красноярск улица Петра Ломако 8</p></div>
                <div><img src={screen2gis} /></div>
                <div className={s.map}>
                    <YMaps>
                        <Map defaultState={defaultState}>
                            <Placemark geometry={[56.053774, 92.894505]}/>
                            <FullscreenControl/>
                            <ZoomControl options={{float: "right"}}/>
                        </Map>
                    </YMaps>
                </div>
            </div>

        </div>
    )
}

export default Contacts;


